<template>
<mynav navIndex="1"></mynav>
<div class='main'>
  <div class='newest'>
    <ul class='article' v-for="(article, index) in allArticles" v-bind:key="index">
      <li><a :href=article.url target="_blank" class="title"><strong>{{article.title}}</strong></a></li>
      <li><a v-if="article.desc" :href=article.url target="_blank" class="desc">{{article.desc}}</a></li>
      <div class="article-info">
        <li class="info">{{article.time}}</li>
      </div>
      <div class="link-light"></div>
    </ul>
  </div>
</div>
<button class="backTop" v-if="topShow" @click="backTop">
  <p>TOP</p>
</button>
</template>

<script>
import axios from "axios";
import datos from "../apps.js"
export default {
  name: 'News',
  data() {
    return {
      topShow: false,
      timestamp: 0,
      hasMoreData: false,
      dataLoading: false,
      offset: 0,
      limit: 15,
      allArticles: [],
      num: 0   
    }
  },
  mounted(){
    this.num = Math.floor(Math.random()*40)
    this.timestamp = Date.parse(new Date()) / 1000
    this.getDataList()
    window.addEventListener('scroll',this.onReachBottom, true)
  },
  beforeUnmount(){
    window.removeEventListener("scroll",this.onReachBottom,true)
  },
  methods:{
    backTop() {
      let top = document.documentElement.scrollTop || document.body.scrollTop;
      const timeTop = setInterval(() => {
        document.body.scrollTop = document.documentElement.scrollTop = top -= 150;
        if (top <= 0) {
          clearInterval(timeTop);
        }
      }, 10);
      this.topShow = false
      // document.documentElement.scrollTop = document.body.scrollTop = 0;
    },
    onReachBottom () {
      const scrollTop = document.documentElement.scrollTop || document.body.scrollTop // 页面滚动的高度
      const clientHeight = document.documentElement.clientHeight // 可视区域高度
      const scrollHeight = document.documentElement.scrollHeight || document.body.scrollHeight // 可滚动内容的高度
      const bottomOfWindow = scrollTop + clientHeight >= scrollHeight - 80 // 是否触底
      if (clientHeight < scrollTop) {
        this.topShow = true
      }else{
        this.topShow = false
      }
      if (bottomOfWindow && this.hasMoreData && !this.dataLoading) {
        this.offset += this.limit
        this.dataLoading = true
        this.getDataList()
      }
    },
    getDataList() {
      axios
      .get("/" + datos()[this.num].desc + "/v1/news/list", {params: {
        offset: this.offset, limit: this.limit, dtype: this.dataType}})
      .then((data) => {
        if (data.data.code == 200) {
          for (let index = 0; index < data.data.data.length; index++) {
            data.data.data[index].url = "/details/news/" + data.data.data[index].news_id + ".html"
            var s = this.timestamp - data.data.data[index].timestamp
            if (s < 60) {
              data.data.data[index].time = s + '秒前'
            }else if (s < 60 * 60) {
              data.data.data[index].time = parseInt(s/60) + '分钟前'
            }else if (s < 24 * 60 * 60) {
              data.data.data[index].time = parseInt(s/60/60) + '小时前'              
            }else{
              var date = new Date(data.data.data[index].timestamp * 1000);
              data.data.data[index].time = date.getFullYear() + '-' + (date.getMonth() + 1) + '-' + date.getDate()
            }
            this.allArticles.push(data.data.data[index])
          }
          if (data.data.data.length < this.limit){
            this.hasMoreData = false
          }else{
            this.hasMoreData = true
          }
          this.dataLoading = false
        }
      })
      .catch((err) => {
        console.log(err);
      })
    }
  }
}
</script>

<style scoped>
.article-main {
  display: flex;
  margin: auto;
  width: 65%;
  top: 80px;
  position: relative;
  left: 0;
  right: 0;
  z-index: 1;
}

.title {
  font-size: 18px;
  color: #242424;
  font-weight: 450;
}
.desc {
  color: #828a92;
}

.article-info {
  display: flex;
}
.info {
  /* color: #439494; */
  color: #958b08;
  font-size: 0.8rem;
}

ul{ 
  text-align: left;
  list-style-type: none;
  margin:0.1rem 0.1rem;
  padding:0.2rem 0.2rem;
}
li {
  padding:5px 2px;
  word-break:break-all
}
li>a:visited {
  color: #828a92;
}
a {
  text-decoration: none;
}

.link-light{
  width: 100%;
  height: 1px;
  margin: auto;
  border-top: solid #e6e6e6 1px
}

.backTop {
  text-align: center;
  position: fixed;
  right: 2rem;
  bottom: 3rem;
  margin: 1rem 0 0;
  /* padding: 0; */
  width: 3.00rem;
  height: 3.00rem;
  /* line-height: 1; */
  color: #909090;
  background-color: #fff;
  border: 1px solid #f1f1f1;
  border-radius: 50%;
  box-shadow: 0 0 5px rgb(0 0 0 / 5%);
  cursor: pointer;
}
@import '../css/main.css';
</style>
